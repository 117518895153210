<template>
  <div class="page-wrap">
    <img
      class="page-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/productFiling_zhengxing/top_new.jpg"
    />
    <input
      class="input"
      v-model="phone_number"
      type="tel"
      placeholder="请输入手机号码"
      maxlength="11"
    />
    <img
      class="order-button"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/productFiling_zhengxing/submit.png"
      @click="onSubmitOrder"
    />
    <div class="protocol-wrap" @click="checked = !checked">
      <img
        class="protocol-check"
        :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/productFiling_zhengxing/${
          checked ? 'checked' : 'unchecked'
        }.png`"
      />
      <span>我已详细阅读并同意</span>
      <span class="protocol-text" @click.stop="visibility = true"
        >《服务协议》</span
      >
    </div>
    <img
      class="page-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/productFiling_zhengxing/equity_new.jpg"
    />
    <img
      class="page-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/productFiling_zhengxing/rule.jpg"
    />
    <van-popup v-model="visibility" class="dialog-wrap">
      <div class="dialog-content">
        <img
          class="dialog-bg"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/productFiling_zhengxing/dialog_bg.png"
        />
        <div class="dialog-text">{{ indexProtocolText }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { alipayNoSignPayAPI } from "@/api/index";
import { Toast } from "vant";
import { indexProtocolText } from "./data.js";
export default {
  data() {
    return {
      phone_number: "",
      checked: false,
      visibility: false,
      indexProtocolText,
    };
  },

  methods: {
    onSubmitOrder() {
      if (!this.phone_number) return Toast("请输入手机号码");
      let filterPhone = /^1[3456789]\d{9}$/;
      const pass = filterPhone.test(this.phone_number);
      if (!pass) return Toast("手机号码格式不正确");
      if (!this.checked) return Toast("请阅读并同意服务协议");
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.page-wrap {
  position: relative;
  .page-img {
    width: 100vw;
  }
  .input {
    width: 85vw;
    height: 96px;
    border: none;
    border-radius: 50px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 730px;
    transform: translateX(-50%);
    padding: 0 32px;
    border: 2px solid #ffcba6;
  }
  .order-button {
    width: 85vw;
    height: 96px;
    border: none;
    position: absolute;
    left: 50%;
    top: 860px;
    transform: translateX(-50%);
  }
  .protocol-wrap {
    width: 100vw;
    position: absolute;
    left: 50%;
    top: 984px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
    color: #6e482c;
    .protocol-check {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .protocol-text {
      color: #ff7322;
    }
  }
  .dialog-wrap {
    background: none;
    overflow: initial;
    .dialog-content {
      position: relative;
      .dialog-bg {
        width: 90vw;
      }
      .dialog-text {
        height: 1000px;
        overflow: auto;
        white-space: pre-wrap;
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        width: 80vw;
        font-size: 28px;
      }
      .dialog-close {
        position: absolute;
        bottom: -100px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 60px;
        z-index: 100;
      }
    }
  }
}
</style>
